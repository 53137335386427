<template>
    <div>

        <v-toolbar dark flat :color="$root.themeColor">
            <v-btn icon @click="$root.fn_goBack">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title style="line-height:20px;padding-top: 6px;">
                {{$root.tripTitle}}
                <!-- <div font-small>Club Trip - Trip Organizer</div> -->
            </v-toolbar-title>
        </v-toolbar>

        <div>

            <v-subheader style="background:#f0f0f0"><strong>Club Trip - Trip Organizer</strong></v-subheader>
            <v-list two-line>
                <v-list-item @click="fn_checkCameraPermission">
                    <v-list-item-icon>
                        <v-icon>mdi-qrcode-scan</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Open QR Scanner</v-list-item-title>
                        <v-list-item-subtitle>Scan QR code to update gifting details</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <!-- <v-divider inset></v-divider>

                <v-list-item @click="fn_changeTrip">
                    <v-list-item-icon>
                        <v-icon>mdi-swap-horizontal</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Change Trip</v-list-item-title>
                        <v-list-item-subtitle>Switch to another trip</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item> -->

                <v-divider inset></v-divider>

                <v-list-item to="/about">
                    <v-list-item-icon>
                        <v-icon>mdi-information-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>About</v-list-item-title>
                        <v-list-item-subtitle>More information about app</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <!-- <template>
                
                    <v-divider inset></v-divider>

                    <v-list-item @click="fn_testFn">
                        <v-list-item-icon>
                            <v-icon>mdi-information-outline</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>For Testing</v-list-item-title>
                            <v-list-item-subtitle>Testing for development</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    
                </template> -->
                
            </v-list>

            <v-alert prominent text color="info" dense style="position:fixed;bottom:0;width:100%" v-if="updateAvailable">
                <v-subheader class="pa-0 info--text">
                    Updated version available
                    <v-spacer></v-spacer>
                    <v-btn small color="primary" @click="fn_downloadUpdate" v-if="$root.downloadStatus == null"><v-icon left>download</v-icon> Install</v-btn>
                    <v-progress-circular
                        v-else
                        :rotate="-90"
                        :size="36"
                        :width="4"
                        :indeterminate="$root.downloadStatus == 0 ? true : false"
                        :value="$root.downloadStatus"
                        color="primary"
                        >
                        <span style="font-size:x-small" v-if="$root.downloadStatus">{{ $root.downloadStatus }}%</span>
                    </v-progress-circular>

                </v-subheader>
                <!-- <v-layout row wrap class="ma-0">
                    <v-flex xs8 sm8 md8>Updated version available</v-flex>
                    <v-flex xs4 sm4 md4>
                        <v-btn icon @click="fn_downloadUpdate"><v-icon left>download</v-icon> Install</v-btn>
                    </v-flex>
                </v-layout> -->
            </v-alert>

            <!-- <div class="pa-8">
                <v-img width="80" style="margin:auto" src="../assets/qr_code.svg"></v-img>

                <v-btn class="mt-4" :disabled="loading" outlined @click="fn_checkCameraPermission">
                    {{loading ? 'Updating details...' : 'Open QR Scanner'}}
                </v-btn>
                <div font-small class="mt-4">Scan QR code to update gifting details</div>
            </div> -->
        </div>

        <v-dialog v-model="dialog" max-width="320px" transition="dialog-transition">
            <v-card>
                <v-card-title primary-title>
                    Scanned Code
                </v-card-title>
                <v-card-text>
                    {{code}}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog=false">Okay</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
// import axios from 'axios';
// import axios from 'axios';

    export default{
        data(){
            return{
                loading:false,
                code:null,
                dialog:false,
                updateAvailable:false,
                confirming:false,
            }
        },
        methods:{

            fn_testFn: function(){
                this.getData('TestFunction', null, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                        },
                        onError: function(error){
                            console.log(error);
                        }
                    }
                ); 
            },

            fn_checkCameraPermission: function(){
                // this.$root.fn_showToast('test')
                let self = this;
                this.getData('CheckCameraPermission', "CameraPermission", 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.fn_scanQR();
                        },
                        onError: function(error){
                            console.log(error);
                        }
                    }
                ); 
            },

            fn_scanQR: function(){
                let self = this;
                this.getData('OpenScanner', null, 
                    {
                        onSuccess: function(response){
                            console.log(response + "Scanner Success");
                            // self.code = response;
                            self.$router.push({path:'/attendeedetails', query:{'requestId':response}})
                            // self.dialog = true;
                            // self.fn_updateScanDetails(response);
                        },
                        onError: function(error){
                            console.log(error + "Scanner Error");
                            this.$root.fn_showToast("Unable to open scanner");
                        }
                    }
                );
            },

            fn_changeTrip: function(){
                this.$router.push({path:'/trips', query:{'goback':1}})
            },

            fn_checkUpdate: function(){
                let self = this;
                this.getData('CheckForUpdate', null, {
                    onSuccess: function(response){
                        console.log(response);
                        if(JSON.parse(response)){
                            self.updateAvailable = true;
                        }
                        else{
                            self.updateAvailable = false;
                        }
                    },
                    onError: function(error){
                        console.log(error);
                    }
                });
            },

            fn_downloadUpdate: function(){
                this.$root.downloadStatus = 0;
                this.getData('InstallUpdate', null, {
                    onSuccess: function(response){
                        console.log(response);
                    },
                    onError: function(error){
                        console.log(error);
                    }
                });
            },

            fn_closeAdminApp: function(){

            }

            // fn_updateScanDetails: function(requestId){

            //     if(!navigator.onLine){
            //         this.$root.fn_showToast("Internet not available");
            //         return;
            //     }

            //     this.loading = true;
            //     this.error = false;

            //     var headersObj = {
            //         headers: { 
            //             'MobileAppKey': '0193c477-8f0b-4a50-970a-e3be5cc6bf99',
            //             // 'ViewName':'Winner',
            //             // 'TripSiteID':7,
            //             'SiteType':3,
            //             // 'Authorization': 'bearer '+token,
            //             }
            //         };
                    

            //     this.$root.fn_showToast('Verifying details...');

            //     axios.get(this.$root.baseUrl + "/api/mobileapp/admin/gifting/"+requestId+"/qrcodescan", headersObj, {timeout:30000})
            //     .then(response => {
            //         this.loading = false;
            //         console.log(response);
            //         this.$root.fn_showToast("Details updated");
            //     }).catch(e => {
            //         this.loading = false;
            //         this.error = true;
            //         console.log(e);
            //         this.$root.fn_showToast("Something went wrong. Try again");
            //     })
            //     // }
            //     // })
            // }
        },
        mounted() {
            this.$root.fn_pageLoadComplete();
            this.fn_checkUpdate();
        },
    }
</script>