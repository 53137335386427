import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
/* eslint-disable */ 
import VerifyOtp from '../components/login/VerifyOtp'
import ClubTrips from '../components/ClubTrips'
import HomePage from '../components/HomePage'
import AttendeeDetails from '../components/AttendeeDetails'
import AboutItems from '../components/about/AboutItems'
import OpenLiabraries from '../components/about/OpenLiabraries'

Vue.use(VueRouter)

const routes = [
  { path:'/', redirect:'/trips'},
  {path: '/verifyotp', name: 'VerifyOtp', component: VerifyOtp},
  {path: '/trips', name: 'ClubTrips', component: ClubTrips},
  {path: '/homepage', name: 'HomePage', component: HomePage},
  {path: '/attendeedetails', name: 'AttendeeDetails', component: AttendeeDetails},
  {path: '/about', name: 'AboutItems', component: AboutItems},
  {path: '/about/liabraries', name: 'OpenLiabraries', component: OpenLiabraries}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
