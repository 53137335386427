import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

import './actioninterface.js';

Vue.config.productionTip = false

Vue.use(vuetify,{
  iconfont: 'md'
})

new Vue({
  vuetify,
  router,
  render: h => h(App),
  data() {
    return {
      tripSiteId:null,
      themeColor:null,
      tripTitle:null,
      downloadStatus:null,
      baseUrl: process.env.NODE_ENV === 'production' ? 'https://platinumclubandgoldclub.azurewebsites.net' : 'https://platinumclubandgoldclub.azurewebsites.net',
      platform:null,
    }
  },
  methods:{
    fn_enterTrip: function(tripId){
      let self = this;
      let pcgcTripId = tripId.toString();
      this.getData('SwitchTrip', pcgcTripId, 
        {
          onSuccess: function(response){
              console.log(response);
              self.fn_setTheme(tripId);
          },
          onError: function(error){
              console.log(error);
              self.$root.fn_showToast("exception");
          }
      });
    },

    fn_setTheme: function(tripId){
      this.tripSiteId = tripId;
      if(tripId == 7){
          this.themeColor = "#395b83";
          this.tripTitle = "Platinum Club"
      }
      else if(tripId == 8){
          this.themeColor = "#d64227";
          this.tripTitle = "AMERCICAS GOLD CLUB"
      }
      else if(tripId == 9){
          this.themeColor = "#004987";
          this.tripTitle = "ASIA GOLD CLUB"
      }
      else if(tripId == 10){
        this.themeColor = "#0093c9";
        this.tripTitle = "EMEA GOLD CLUB"
      }
      else if(tripId == 11){
          this.themeColor = "#004987";
          this.tripTitle = "Phuket Club Trip"
      }
      else if(tripId == 17){
        this.themeColor = "#003765";
        this.tripTitle = "AMERCICAS GOLD CLUB"
      }
      else if(tripId == 18){
        this.themeColor = "#005474";
        this.tripTitle = "ASIA GOLD CLUB"
      }
      else if(tripId == 19){
        this.themeColor = "#201650";
        this.tripTitle = "EMEA GOLD CLUB"
      }
      else if(tripId == 20){
        this.themeColor = "#5cbfbf";
        this.tripTitle = "Platinum Club Trip"
      }
      this.$router.replace('/homepage');
    },

    fn_showToast: function(msg){
      let message;
      if(msg === 'exception'){
        message = 'Something went wrong. Please try again'
      }
      else if(msg === 'nointernet'){
        message = 'Internet not available'
      }
      else{
        message = msg
      }
      this.getData('ShowToast', message, 
          {
              onSuccess: function(response){
                  console.log(response);
              },
              onError: function(error){
                  console.log(error);
              }
          }
      ); 
    },

    fn_pageLoadComplete: function(){
      this.getData('onPageLoadCompleted', null, 
      {
          onSuccess: function(response){
              console.log(response);
          },
          onError: function(error){
              console.log(error);
          }
      })
    },

    fn_openExternalPage: function(pageUrl){
      this.getData('OpenBrowser', pageUrl, {
        onSuccess: function(response){
            console.log(response);
        },
        onError: function(error){
            console.log(error);
        }
      });
    },

    fn_goBack: function(){
      if(this.$root.platform === 'ios'){
        this.fn_iosGoBack(); 
      }
      else{
        this.$route.name === 'HomePage' ? this.exitApp() : this.$router.go(-1);
      }
    },

    fn_iosGoBack: function(){
      if(this.$route.name === 'HomePage'){
        this.getData('ExitApp', null, 
        {
            onSuccess: function(response){
                console.log(response);
            },
            onError: function(error){
                console.log(error);
            }
        });
      }
      else{
        this.$router.go(-1)
      }
    },

    fn_downloadStatus: function(value, path){
      console.log(value, path);
      if(path){
        this.downloadStatus = null;
        this.fn_openInstallDialog(path);
      }
      else{
        this.downloadStatus = value;
      }
    },

    fn_openInstallDialog: function(path){
      this.getData('OpenInstallDialog', path, {
        onSuccess: function(response){
            console.log(response);
        },
        onError: function(error){
            console.log(error);
        }
      });
    }
  },
  mounted() {
    window.js_globals.fn_onBackPress = this.fn_goBack;
    window.js_globals.fn_setStatus = this.fn_downloadStatus;
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.iosMessageInterface) {
      this.platform = "ios"
    }
    /*global AndroidRoutingTest*/
    else if (typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null) {
      this.platform = "android"
    }
    else {
      this.platform = "web"
    }
  },
}).$mount('#app')
